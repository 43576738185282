
export default class ProptiesGrid {
    
    static controlCount = 0;

    items: Item[];
    gridId: string;
    lastIndex?: number;
    compactViewMode?: string;
    private _localKey?: string;
    private get localKey() {
        return this._localKey == null ? null : `tabsPersistent_${this._localKey}`;
    }

    private _storedTabId?: string | null;

    constructor(options: {
        persistentKey?: string,
        enableUrlFilter?: boolean,
        id?: string
    } = {}) {
        this.items = [];
        this.gridId = options.id ?? `${ProptiesGrid.controlCount++}`;
        this._localKey = options.persistentKey;
    }

    addItem(item: Item) {
        debugger;
        if (!item.id) {
            item.id = `o365-tab-${this.gridId}_${this.items.length}`;
        }

        if (this._storedTabId != null) {
            const isActive = this._storedTabId === item.id;
            item.rendered = isActive;
            item.active = isActive;
        }

        item.index = this.items.length;
        this.items.push(item);
    }

}


export class Item {
    private _props: ItemProps;

    private _id: string
    private _active: boolean;

    rendered: boolean;
    index?: number;
    elRef: Ref<HTMLElement>;
    titleSlot: Function;

    get props() { return this._props; }

    get id() { return this._id; }
    set id(pValue) { this._id = pValue;}

    get active() { return this._active}
    set active(pValue) { this._active = pValue; }

    get tabIndex() { return this._props.itemIndex; }
    get title() { return this._props.title; }
    get alwaysRender() { return this._props.alwaysRender; }
    get paneClass() { return this._props.paneClass; }
    get paneStyle() { return this._props.paneStyle; }
    get itemClass() { return this._props.itemClass; }
    get itemStyle() { return this._props.itemStyle; }
    get linkClass() { return this._props.linkClass; }
    get linkStyle() { return this._props.linkStyle; }

    constructor(options: {
        props: ItemProps,
        elRef: Ref<HTMLElement>,
        titleSlot: Function,
    }) {
        this._props = options.props;
        this._id = options.props.id ?? '';
        this._active = options.props.active ?? false;
        this.elRef = options.elRef;
        this.rendered = !!this.active;
        this.titleSlot = options.titleSlot;
    }
}

// Copy of tab props type for inelisense
type ItemProps = {
    id?: string,
    itemIndex?: string | number,
    active?: boolean,
    title?: string,
    alwaysRender?: boolean,
    paneClass?: any,
    paneStyle?: any,
    itemClass?: any,
    itemStyle?: any,
    linkClass?: any,
    linkStyle?: any,
    disabled?: boolean
};

type Ref<T> = {
    value: T
};
